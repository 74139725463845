import * as PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function NotFound({ location, data }) {
  // 404 page is always in default language.
  return (
    // Give info to component to not display the language selector with "is404Page" prop.
    <Layout location={location} locale="fr" is404Page>
      <SEO
        title="404 : Page introuvable"
        description="Page Erreur 404 Site Web DatArt"
        language="fr"
      />
      <BackgroundImage
        tag="div"
        className="d-table w-100 vh-100"
        fluid={data.datArtHomeHeaderImage.childImageSharp.fluid}
      >
        <div className="d-table-cell align-middle text-center">
          <h1
            className="d-inline-block title-font-size text-white text-center bg-darken rounded p-4
            mx-4"
          >
            Page introuvable
          </h1>
        </div>
      </BackgroundImage>
    </Layout>
  );
}

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`
    fragment homeHeaderFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    query {
        datArtHomeHeaderImage: file(relativePath: {regex: "/datArtHomeHeaderImage/"}) {
            ...homeHeaderFluidImage
        }
    }
`;
